import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home/Home";
import Company from "./components/Company/Company";
import Consulting from "./components/Consulting/Consulting";
//import Customers from "./components/Customers/Customers";
import Careers from "./components/Careers/Careers";
import Products from "./components/Products/Products";
import SignIn from "./components/SignIn/SignIn";
import SignUp from "./components/SignUp/SignUp";
import AboutUs from "./FooterComponents/Aboutus";
import Blog from "./FooterComponents/Blog";
import Membership from "./FooterComponents/Membership";
import MoneyBackGuarantee from "./FooterComponents/Moneybackguarantee";
import PrivacyPolicy from "./FooterComponents/Privacypolicy";
import PurchaseGuide from "./FooterComponents/Purchaseguide";
import StartHere from "./FooterComponents/Starthere";
import TermsAndServices from "./FooterComponents/Termsandservices";
import { useState, useEffect } from "react";
import BarLoader from "react-spinners/BarLoader";
import "./App.css";
export default function App() {
  const[loading,setLoading]=useState(true)
  useEffect(()=>{
    // setLoading(true)
    setTimeout(()=>{
      setLoading(false)
    },1000)
  },[])
  return (
   <>
  <div className="App">
      
      {
        loading?
      
      (
        <div className="loader-container"
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#000000', // Semi-transparent black background
          zIndex: 9999 // Ensure it's above other elements
        }}>
          <BarLoader color={"#FFFFFF"} loading={loading} size={150} />
        </div>
      ) 
     
      :
         
        <div>
        
            <BrowserRouter>
            <Header/>
            <div className="main-content">
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/Company" element={<Company />} />
                <Route path="/Consulting" element={<Consulting />} />
                {/* <Route path="/Customers" element={<Customers />} /> */}
                <Route path="/Products" element={<Products />} />
                <Route path="/Careers" element={<Careers />} />
                <Route path="/SignIn" element={<SignIn />} />
                <Route path="/SignUp" element={<SignUp />} />
              </Routes>
              </div>
              <Footer />
                <Routes>
                  <Route path="/AboutUs" element={<AboutUs />} />
                  <Route path="/Blog" element={<Blog />} />
                  <Route path="/Membership" element={<Membership />} />
                  <Route path="/MoneyBackGuarantee" element={<MoneyBackGuarantee />} />
                 <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
                 <Route path="/PurchaseGuide" element={<PurchaseGuide />} />
                 <Route path="/StartHere" element={<StartHere />} />
                 <Route path="/TermsAndServices" element={<TermsAndServices />} />
               </Routes>
            </BrowserRouter>
          </div>
      }
   </div> 
   </>
  );
}