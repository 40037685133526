import React, { useState } from 'react';
import ContactUs from '../ContactUs';
import ContactUs1 from '../ContactUs1';
import Paragraph from "../../ExternalCom/Paragraph/Paragraph";
import Heading from "../../ExternalCom/Heading/Headingcom";
import Images6 from "../../Images/newimg6.png"
import Images7 from "../../Images/newimg7.png"
import Images8 from "../../Images/newimg8.png"
import Images9 from "../../Images/newimg9.png"
import Images10 from "../../Images/newimg10.png"
import Images11 from "../../Images/t-6.png"
import Images12 from "../../Images/2.png"
import Images13 from "../../Images/1.png"
import './Consulting.css'; 

const Consulting = () => {
    return (
        <>
        <section className="sec">
        <div className='const-wrapper'>
        <div class="triangle-img">
        <img src={Images11} alt="no image" width="200" height="200" />
        </div>
        <div class="man-triangle-img">
        <img src={Images12} alt="no image" width="100" height="100" />
        </div>
        <div className='img-container'>
        <div className='img-wrapper'>
            <div className="img-bg1" style={{display:"flex", justifyContent:"center"}}>
            <img src= {Images6} alt="no image" width="300" height="300" style={{transform:"scaleX(-1)"}} />
            </div>
        </div>
        </div>
        <div className='content-text1'>
        <Heading level={1} className="fw-bolder5" children="On Boarding Management"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
                Welcome new talent with seamless processes and personalized
                experience to set the stage for <br />
                <span style={{color:'blue'}}>Success from day one</span>
            </Paragraph>
        </div>
        <div className='content-text2'>
        <Heading level={1} className="fw-bolder5" children="Training and Development:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Nurturing the potential of your workforce
            through custom-tailored training programs 
            designed to enhance skills and drive growth.
            </Paragraph>
        </div>
        </div>
        </div>
        <div className='cont-img'>
        <div className='content-text3'>
            {/* <div className="circle"></div> */}
        <Heading level={1} className="fw-bolder5" children="Performance Management:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Empowering employees to reach their full
            potential with effective performance
            evaluation and feedback mechanisms.
            </Paragraph>
        </div>
        {/* <div className="circle3"></div> */}
        <div className='content-text4'>
        <Heading level={1} className="fw-bolder5" children="Appraisal Process Support:" />
        <div className='const-para'>
            <Paragraph className='para-text'>
            Streamlining the appraisal process to recognize          
            achievements,address challenges, and foster   
             continuous improvement.
            </Paragraph>
        </div>
        </div>
        </div>
        <div className='img-container2'>
            
        <div className='img-wrapper' >
        <div className="img-bg2"> </div>
            <img src={Images7} alt="" width="300" height="300" style={{zIndex:"2", position:"relative", left:"30px"}}  />
       
        </div>
        </div>
        </div>

        

        <div className='img-container' style={{position:"relative"}}>
            <div className="img-bg3">    </div>   
                <div className="img-bg11"></div>
        <div className='img-wrapper'>
            <img src={Images8} alt="" width="300" height="300" style={{zIndex:2}} />
        
        
        </div>
        </div>
        <div className='content-text1'>
        <Heading level={1} className="fw-bolder5" children="Risk Management:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Proactively mitigating risks and ensuring compliance with 
            labor laws and regulations to safeguard your organization's
            reputation and success.
            </Paragraph>
        </div>
        <div className='content-text2'>
        
        <Heading level={1} className="fw-bolder5" children="Employee Relations:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Cultivating positive workplace relationships
            and resolving conflicts with empathy, fairness,
            and professionalism.
            </Paragraph>
        </div>
        </div>
        </div>
    
    <div className="cont-img">
        <div className='content-text3'>
        {/* <div className="circle2"></div> */}
        <Heading level={1} className="fw-bolder5" children="Employee Engagement:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Creating a vibrant and inclusive work culture where
            employees feel valued, motivated, and inspired to
            contribute their best.
            </Paragraph>
        </div>
        <div className='content-text4'>
        <Heading level={1} className="fw-bolder5" children="Offboarding:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Providing relief from administrative burdens 
            so you can focus on your core business
            objectives with peace of mind.
            </Paragraph>
        </div>
        </div>
        </div>
        <div className='img-container2'>
            <div className="img-bg4"></div>
        <div className='img-wrapper' style={{zIndex:"2"}}>
            <img src={Images9} alt="" width="300" height="300" style={{zIndex:"1", position:"relative"}}/>
        
        </div>
        </div>
        </div>
  
        <div className='img-container' style={{position:"relative"}}>
            <div className="img-bg5">
                <div className="img-bg10"></div>
        <div className='img-wrapper'>
            <img src={Images10} alt="" style={{position:"relative", marginRight:"20px", marginBottom:"50px", width:"270px", height:"300px", zIndex:"2"}} />
        </div>
        
        </div>
        </div>
        <div className='content-text1'>
        <div class="man-triangle-img2">
        <img src={Images13} alt="no image" width="100" height="100" />
        </div>
        <Heading level={1} className="fw-bolder5" children="Documentation:"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Maintaining accurate and up-to-date HR records
            and documentation to support organizational 
            transparency and accountability.
            </Paragraph>
        </div>
        <div className='content-text2'>
        <Heading level={1} className="fw-bolder5" children="MIS(Management Information System):"/>
        <div className='const-para'>
            <Paragraph className='para-text'>
            Harnessing the power of data-driven insights 
            to inform strategic decision-making and drive 
            organizational performance.
            </Paragraph>
        </div>
        </div>
        </div>
        

        <div className="foot">
            <h1>Contact us today to learn how we can tailor our services to meet your unique needs and propel your business forward</h1>
            <ContactUs className="reach-us-button" removeDefaultClass={true} >Reach Us</ContactUs>
        </div>


</div>

    </section>
    </>
    );
};

export default Consulting;