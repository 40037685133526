import React, { useState } from 'react';
import './ContactUs.css';
import emailjs from 'emailjs-com';

const ContactUs = ({ className, style, removeDefaultClass = false, children = "Contact Us" }) => {
  const buttonClass = removeDefaultClass ? className : `button-3 ${className}`;
  const [showForm, setShowForm] = useState(false);
  const [showThankYou, setShowThankYou] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    position: '',
    education: '',
    skills: '',
    interest: '',
    resumeLink: ''
  });

  const toggleForm = () => {
    setShowForm(!showForm);
    setShowThankYou(false);
  };

  const handleClose = () => {
    setShowForm(false);
    setShowThankYou(false);
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_e19r6u5', 'template_hkf675u', e.target, 'MjabMJpliFt8sJYwZ')
      .then((result) => {
        console.log('Email sent successfully:', result.text);
        setFormData({
          name: '',
          email: '',
          phone: '',
          position: '',
          education: '',
          skills: '',
          interest: '',
          resumeLink: ''
        });
        setShowForm(false);
        setShowThankYou(true);
      })
      .catch((error) => {
        console.error('Email sending failed:', error);
      });
  };

  return (
    <>
      <div>
        <button className={buttonClass} onClick={toggleForm}>{children}</button>

        <div className={`popup-background ${showForm || showThankYou ? 'active' : 'inactive'}`}></div>

        {showForm && (
          <div className="popup">
            <form onSubmit={handleSubmit}>
              <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
              <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
              <textarea name="interest" value={formData.interest} onChange={handleChange} placeholder="Write your query" required />
              <button type="submit">Submit</button>
            </form>
            <button className="close-button" onClick={handleClose}>Close</button>
          </div>
        )}

        {showThankYou && (
          <div className="popup">
            <h2 style={{textAlign:"center", color:"green", fontSize:"20px"}}>Thanks for reaching us!</h2>
            <button className="close-button" onClick={handleClose}>Close</button>
          </div>
        )}
      </div>
    </>
  );
};

export default ContactUs;
