//import React from "react";
import React, { useState } from 'react';
import Paragraph from "../../ExternalCom/Paragraph/Paragraph";
import Heading from "../../ExternalCom/Heading/Headingcom";
// import '../../ExternalCom/Button/Buttoncom.scss';
// import Button from "../../ExternalCom/Button/Buttoncom";
//import JobPositionexp from "../../ExternalCom/Jobs/JobPositionexp";
import './Careers.css'; 
import './Form.css'; 
import Images from "../../Images/newimg1.png";
import Images2 from "../../Images/newimg2.png";
import Images3 from "../../Images/newimg3.png";
import emailjs from 'emailjs-com';
import { FaCheckCircle } from 'react-icons/fa';

export default function Careers () {
    const [isSubmitted, setIsSubmitted] = useState(false)
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        education: '',
        skills: '',
        interest: '',
        resumeLink: ''
      });
    
      const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
    
       
        emailjs.sendForm('service_e19r6u5', 'template_x2h66fg', e.target, 'MjabMJpliFt8sJYwZ')
          .then((result) => {
            console.log('Email sent successfully:', result.text);
            setIsSubmitted(true);
            setFormData({
              name: '',
              email: '',
              phone: '',
              position: '',
              education: '',
              skills: '',
              interest: '',
              resumeLink: ''
            });
          })
          .catch((error) => {
            console.error('Email sending failed:', error);
          });
      };
    return(
       <>
       {/* <section className="sec-01">
            <div className="contains">
                <div className="career-contents1">
                    <div className="career-image1">
                        <img src="./images/Hero2.png" alt=" " width="1920" height="734"/>
                    </div>
                </div>
            </div>
       </section> */}
        
       <section className="sec-02">
            <div className="contains1" >
                <div className="career-contents01">
                    <div className="career-contents1">
                        <div className="triangle-6"></div>
                        <div className="career-image1" style={{zIndex:"2"}}>
                            <img src={Images} alt="no image " width="300" height="300" />
                        </div>
                    </div>
                    <div className="career-show1">
                            <div className="intro-career1">
                                <Heading level={1} className="fw-bolder1" children="Welcome to the Gateway of Your Professional Transformation!"/>
                            </div>
                        <div className="paracom1">
                            <Paragraph
                            className="career-para1"
                            children={<>"At HR Labs, we believe in sculpting careers, igniting potentials,  and building legacies. 
                            With a passion for human capitaldevelopment and an unwavering commitment to excellence, 
                            we are the partners in navigating the ever-evolving modern workplaces."
                            </>} />
                        </div>
                    </div>
                    
                </div>
            </div>
    </section>




       <section className="sec-3">
            <div className="contains2" >
                <div className="carer-contents02">
                    <div className="career-show2">
                           <div className="intro-career">
                                <Heading level={1} className="fw-bolder2" children=" Why Choose Us?"/>
                            </div>
                            <div className="para-contents">
                        <div className="paracom2">
                            <Paragraph
                            className="career-para2"
                           
                            children={<>
                                <span style={{ fontWeight: 700 }}>Expertise: </span> Backed by 21 years of industry experience and 
                                a team of seasoned <br /> professionals,
                                 we bring a wealth of knowledge and insight to every client <br /> partnership.
                            </>}
                            />
                        </div>
                        
                        <div className="paracom2">
                            <Paragraph
                            className="career-para2"
                            children={<>
                                <span style={{ fontWeight: 700 }}> Tailored Solutions: </span> We understand that every organization is unique. 
                                That’s  <br />why we offer customized HR solutions designed to address 
                                their specific <br />challenges and propel their business forward.
                            </>}
                            />
                        </div>
                        </div>
                        <div className="para-contents">
                        <div className="paracom2">
                            <Paragraph
                            className="career-para2"
                            children={<>
                                <span style={{ fontWeight: 700 }}> Innovation: </span> In a rapidly changing world, 
                                innovation is key to staying ahead. <br /> We pride ourselves on our ability to anticipate trends, 
                                leverage cutting <br />edge technologies, and pioneer new approaches to consulting.
                            </>}
                            />
                        </div>
                        <div className="paracom2">
                            <Paragraph
                            className="career-para2"
                            children={<>
                                <span style={{ fontWeight: 700 }}> Partnership Approach:</span> We don’t just see ourselves as consultants; 
                                we see ourselves as your <br /> allies on the journey to success. 
                                Our collaborative approach ensures that we work hand-in-hand <br /> with you to achieve your goals.
                            </>}
                            />
                        </div> 
                        </div>  
                    </div>
                    <div className="career-contents2" style={{position:"relative"}}>
                        <div className="circle-6"></div>
                            <div className="career-image2" style={{zIndex:"2"}}>
                                    <img src={Images2}  alt=" " width="300" height="500"/>
                                </div>
                           
                    </div>
                    
                </div>
            </div>
    </section>



    <section className="sec-04">
    <div className="contains3">
        <div className="career-contents03">
            <div className="career-contents3" style={{position:"relative"}}>
            <div className="triangle-7"></div>
            <div className="triangle-8"></div>
                <div className="career-image3" style={{zIndex:"2"}}>
                    <img src={Images3} alt="Career Image" width="250" height="150" />
                </div>
            </div>
            <div className="career-show3">
                <div className="intro-career">
                    <Heading level={1} className="fw-bolder3">Join Us:</Heading>
                </div>
                <div className="para3-contents">
                <div className="paracom3">
                    <Paragraph
                        className="career-para3"
                        children="Are you ready to take your career to the next level? 
                            Whether you’re a seasoned professional looking for new challenges or a recent graduate eager to make your mark in the industry, 
                            we want to hear from you."
                    />
                </div>
                <div className="paracom3">
                    <Paragraph
                        className="career-para3"
                        children="Explore our current openings and discover why HR Labs is the perfect place to unleash your potential, 
                            cultivate your talents, and shape your future!"
                    />
                </div>
                <div className="paracom3">
                    <Paragraph
                        className="career-para3"
                        children="Embark on a journey of growth, fulfillment, 
                            and success with HR Labs. Your career transformation starts here."
                    />
                </div>
                </div>
            </div>
            
        </div>
    </div>
</section>

    {/* <section className="sec-05">
         <div className='job_form_container'>
            <div className="career-contents2">
                <div className="form_header_container">
                    <div className="job_form_header">
                        <h2>Here are the positions we’re looking forward to filling at HRLABS:</h2>
                    </div>
                    <div className="empty_space"></div>
                </div>
                <div className='job_form_content'>
                    <div className='job_form_body'>
                        <JobPositionexp/>
                    </div>
                </div>
            </div>
        </div>
    </section> */}
    
    <section className="sec-05">
        <div className="job_form_container">
        <div className='career-contents3'>
                    {!isSubmitted ? (
                        <>
                        <div className="form_header_container">
                            <div className="job_form_header">
                                <h2>Fill out this form and we'll reach out to you:</h2>
                            </div>
                            <div className="empty_space"></div>
                        </div>
                        <div className="job_form_content">
                            <div className="job_form_body">
                                <form onSubmit={handleSubmit} className="job_form">
                                    <div className="form_row">
                                        <div className="form_group">
                                            <label htmlFor="full_name">Full Name</label>
                                            <input type="text" id="full_name" name="name" className="form_control" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="position">Position</label>
                                            <input type="text" id="position" name="position" className="form_control" value={formData.position} onChange={handleChange} placeholder="Position" required />
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <div className="form_group">
                                            <label htmlFor="email">Email</label>
                                            <input type="email" id="email" name="email" className="form_control" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="phone">Phone</label>
                                            <input type="tel" id="phone" name="phone" className="form_control" value={formData.phone} onChange={handleChange} placeholder="Your Phone" required />
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <div className="form_group">
                                            <label htmlFor="education">Education</label>
                                            <input type="text" id="education" name="education" className="form_control" value={formData.education} onChange={handleChange} placeholder="Your Education" required />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="resumeLink">Resume Link</label>
                                            <input type="text" id="resumeLink" name="resumeLink" className="form_control" value={formData.resumeLink} onChange={handleChange} placeholder="Resume Link" required />
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <div className="form_group">
                                            <label htmlFor="skills">Your Skills</label>
                                            <textarea name="skills" id="skills" className="form_control" value={formData.skills} onChange={handleChange} placeholder="Your Skills" required />
                                        </div>
                                        <div className="form_group">
                                            <label htmlFor="interest">Why I'm interested</label>
                                            <textarea name="interest" id="interest" className="form_control" value={formData.interest} onChange={handleChange} placeholder="Why I'm interested" required />
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <button type="submit" className="submit_button" style={{ margin: "0 auto", width: "25%" }}>Submit</button>
                                    </div>
                                    <div className="form_response_output"></div>
                                </form>
                            </div>
                        </div>
                        </>
                    ) : (
                        <div className="thank_you_message flex flex-row gap-4">
                        <FaCheckCircle className='text-green-500 text-2xl mt-1'/>
                            <h2 className='md:text-2xl text-green-500'>Thanks for connecting with us!</h2>
                        </div>
                    )}
                </div>
        </div>

    </section>
       </>
    )
}   